const state = {
  custom: true,
  width: 0,
  height: 0,
  extra: 0,
  extras: [],
  area: 0,
  diameter: 0,
  shape: null,
  areaExpanded: 0,
  productsPrice: [],
  productsPriceOff: [],
}

const getters = {
  custom: (store) => store.custom,
  width: (store) => store.width,
  height: (store) => store.height,
  extra: (store) => store.extra,
  extras: (store) => store.extras,
  area: (store) => store.area,
  shape: (store) => store.shape,
  diameter: (store) => store.diameter,
  areaExpanded: (store) => store.areaExpanded,
  productsPrice: (store) => store.productsPrice,
  productsPriceOff: (store) => store.productsPriceOff,
}

const mutations = {
  SET_DIMENSION(state, payload) {
    state.custom = payload.custom
    state.width = payload.width
    state.height = payload.height
    state.shape = payload.shape
    state.diameter = payload.diameter
    if (!payload.custom) {
      state.extra = payload.extra
      state.areaExpanded =
        (payload.width + payload.extra * 2) *
        (payload.height + payload.extra * 2).toFixed(2)
    } else {
      state.extras = payload.extras
      state.areaExpanded = (
        (payload.width + (payload.extras[0] + payload.extras[2])) *
        (payload.height + (payload.extras[1] + payload.extras[3]))
      ).toFixed(2)
    }

    state.area = parseFloat(payload.area.toFixed(2))
  },
  SET_PRODUCTS_PRICE(state, productsPrice) {
    state.productsPrice = productsPrice
  },
  SET_PRODUCTS_PRICE_OFF(state, productsPriceOff) {
    state.productsPriceOff = productsPriceOff
  },
}

const actions = {
  /**
   * Setea las dimensiones del balneario
   * @param {vuex}        context
   * @param {Object}      payload Objeto de los detalles de la piscina
   * @param {Number}      [payload.width] Ancho de la piscina
   * @param {Number}      [payload.height] Alto de la piscina
   * @param {Number}      [payload.diameter] Diámetro de la piscina
   * @param {Number}      [payload.extra] Tamaño extra para todos los lados de la piscina
   * @param {Number[]}    [payload.extras] Tamaño extra para cada unon de los lados de la piscina
   * @param {Boolean}     payload.justArea Solo calculo del area?
   * @param {Number}      payload.area Área de la piscina
   * @param {Boolean}     [payload.custom] Se agrega el tamaño personalizando el tamaño de cada lado?
   * @param {String}      [payload.shape='RECT'] [RECT, CIRCLE] Forma de la piscina
   */
  async setDimension(
    context,
    {
      width,
      height,
      diameter,
      extra,
      extras,
      justArea,
      area,
      custom,
      shape = 'RECT',
    }
  ) {
    const payload = {
      width,
      height,
      diameter,
      extra,
      extras,
      justArea,
      area,
      custom,
      shape,
    }
    if (!payload.width) payload.width = 0
    if (!payload.height) payload.height = 0
    if (!payload.diameter) payload.diameter = 0
    if (!['ANYWAY', 'RECT', 'CIRCLE'].includes(payload.shape))
      throw new ReferenceError(`${payload.shape} shape is not supported`)
    console.log(payload)

    context.commit('SET_DIMENSION', payload)
    context.dispatch('setProductsPrice')
  },
  /** Redirige para setear el precio según el producto */
  async setProductsPrice(context) {
    const material = context.getters.navMaterial.title
    context.commit('SET_PRODUCTS_PRICE_OFF', [])

    if (material === 'Aquasol') context.dispatch('calcThermal')
    else if (material === 'Lona') context.dispatch('calcCanvas')
  },
  /** Calcula por el cobertor térmico */
  async calcThermal(context) {
    try {
      const area = context.getters.area
      const productsThermal = context.getters.productsThermal
      let offert_active = false

      // precios de productos
      const productsPrice = productsThermal.map((product) => {
        // Busca el precio del material segun el área
        const obj_price = product.prices.find((obj_price) => {
          if (area >= obj_price.min_dimention) {
            return obj_price
          }
        })
        if (obj_price.offerts) offert_active = true

        const shape = context.getters.shape
        const base_price = parseFloat(
          shape === 'RECT' ? obj_price.price : obj_price.price_designed
        )
        const price =
          shape === 'RECT'
            ? area * obj_price.price
            : area * obj_price.price_designed
        const productPrice = {
          id: product.id,
          material: product.name,
          price: price.toFixed(2),
          base_price: base_price,
          type_pool: obj_price.name,
          badge: 'USD',
          symbol: '$',
        }
        return productPrice
      })
      // Setea los precios de los materiales ya calculados
      context.commit('SET_PRODUCTS_PRICE', productsPrice)

      // Si no hay ofertas activas, termina el proceso
      if (!offert_active) return

      // precios de productos con descuento
      const productsPriceOff = productsThermal.map((product) => {
        // Busca el precio del material segun el área
        const obj_price = product.prices.find((obj_price) => {
          if (area >= obj_price.min_dimention) {
            return obj_price
          }
        })

        const discount = obj_price.offerts.find((discount) => {
          if (area > discount.min_area) return discount
        }) || { off: 0 }

        const shape = context.getters.shape
        const base_price = parseFloat(
          shape === 'RECT' ? obj_price.price : obj_price.price_designed
        )
        const price =
          shape === 'RECT'
            ? area * obj_price.price
            : area * obj_price.price_designed

        const productPrice = {
          id: product.id,
          material: product.name,
          off: discount.off * 100,
          price: (price * (1 - discount.off)).toFixed(2), // Precio del producto
          base_price: parseFloat((base_price * (1 - discount.off)).toFixed(2)), // Precio base por m2
          badge: 'USD',
          symbol: '$',
        }
        console.log('productPrice', productPrice)
        return productPrice
      })
      // Setea los precios de los materiales ya calculados
      context.commit('SET_PRODUCTS_PRICE_OFF', productsPriceOff)
    } catch (error) {
      console.error('[calcModule][calcThermal] error -', error)
    }
  },
  /** Calcula por el cobertor de lona */
  async calcCanvas(context) {
    try {
      const area = context.state.areaExpanded
      const productsCanvas = context.getters.productsCanvas
      let offert_active = false

      // precios de productos
      const productsPrice = productsCanvas.map((product) => {
        // Busca el precio del material segun el área
        const obj_price = product.prices.find((obj_price) => {
          if (context.state.area >= obj_price.min_dimention) {
            return obj_price
          }
        })
        if (obj_price.offerts) offert_active = true

        const shape = context.getters.shape
        const base_price = parseFloat(
          shape === 'RECT' ? obj_price.price : obj_price.price_designed
        )
        const price =
          shape === 'RECT'
            ? area * obj_price.price
            : area * obj_price.price_designed
        const productPrice = {
          id: product.id,
          material: product.name,
          price: price.toFixed(2),
          base_price: base_price,
          type_pool: obj_price.name,
          badge: 'USD',
          symbol: '$',
        }
        return productPrice
      })
      // Setea los precios de los materiales ya calculados
      context.commit('SET_PRODUCTS_PRICE', productsPrice)

      // Si no hay ofertas activas, termina el proceso
      if (!offert_active) return

      // precios de productos con descuento
      const productsPriceOff = productsCanvas.map((product) => {
        // Busca el precio del material segun el área
        const obj_price = product.prices.find((obj_price) => {
          if (area >= obj_price.min_dimention) {
            return obj_price
          }
        })

        const discount = obj_price.offerts.find((discount) => {
          if (area > discount.min_area) return discount
        })

        const shape = context.getters.shape
        const base_price = parseFloat(
          shape === 'RECT' ? obj_price.price : obj_price.price_designed
        )
        const price =
          shape === 'RECT'
            ? area * obj_price.price
            : area * obj_price.price_designed
        const productPrice = {
          id: product.id,
          material: product.name,
          off: discount.off * 100,
          price: (price * (1 - discount.off)).toFixed(2),
          base_price: (base_price * (1 - discount.off)).toFixed(2),
          badge: 'USD',
          symbol: '$',
        }
        return productPrice
      })
      // Setea los precios de los materiales ya calculados
      context.commit('SET_PRODUCTS_PRICE_OFF', productsPriceOff)
    } catch (error) {
      console.error('[calcModule][calcThermal] error -', error)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
