<template>
  <div class="head">
    <div class="head-content">
      <!-- Configuraciones -->
      <Settings />
      <!-- Logo -->
      <picture>
        <img src="../../assets/logo.png" alt="Pool Work Logo" />
        <p class="slogan">Cotizaciones</p>
      </picture>
      <!-- IGV -->
      <input
        v-model="includeIGV"
        type="checkbox"
        class="chk"
        title="Incluir IGV"
        @click="changeIncludeIGV"
      />
    </div>
  </div>
</template>

<script>
import Settings from '@/components/header/Settings.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Head',
  components: {
    Settings,
  },
  computed: {
    ...mapGetters(['includeIGV']),
  },
  methods: {
    ...mapMutations(['SET_INCLUDE_IGV']),
    changeIncludeIGV() {
      this.SET_INCLUDE_IGV(!this.includeIGV)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/sass/global.sass'

$logo-width: 142px

.head
    &-content
        display: flex
        justify-content: space-around
        align-items: center
        picture
            width: $logo-width
            img
                width: 100%
                z-index: 1
            p
                text-align: center
                color: $primary-color
                margin: 0px
                cursor: default
            .slogan
                font-size: 12px
                margin-left: 30px
                background: #fff
                // font-style: italic
        .chk
            width: 16px
            height: 16px
        .chk,
            cursor: pointer
</style>
