const state = {
  exchangeCurrency: null,
}

const getters = {
  exchangeCurrency: (state) => state.exchangeCurrency,
}

const mutations = {
  SET_EXCHANGE_CURRENCY(state, exchangeCurrency) {
    state.exchangeCurrency = exchangeCurrency
  },
}

const actions = {
  /**
   * @param {vuex} context
   * @returns
   */
  async getLastExchangeCurrency(context) {
    try {
      /**
       * @returns {Object} { PEN:Number, US:Number, last_api_update:Number,last_update:Number }
       */
      const exchangeCurrency = await fetch(
        `https://www.poolworkconf.com/backend/content/_exchange.json`
      )
      const exchangeCurrencyJson = await exchangeCurrency.json()
      context.commit('SET_EXCHANGE_CURRENCY', exchangeCurrencyJson)
    } catch (error) {
      return
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
