const state = {
  productsThermal: [
    // Cobertor Translucido
    {
      id: '1-thermal',
      name: 'Translúcido',
      fullName: 'Cobertor Translúcido',
      type: 'cover',
      prices: [
        // Piscina Olímpica
        {
          min_dimention: 1000.0,
          price: 7.37,
          price_designed: 7.37,
          name: 'Piscina Olímpica',
          offerts: [],
        },
        // Piscina Semiolímpica
        {
          min_dimention: 300.0,
          price: 9.41,
          price_designed: 9.41,
          name: 'Piscina Semiolímpica',
          offerts: [],
        },
        // Piscina Comercial
        {
          min_dimention: 100.0,
          price: 9.61,
          price_designed: 9.61,
          name: 'Piscina Comercial',
          offerts: [
            {
              min_area: 250.0,
              off: 0.05,
            },
          ],
        },
        // Piscina Doméstica
        {
          min_dimention: 10.0,
          price: 9.83,
          price_designed: 11.83,
          name: 'Piscina Doméstica',
          offerts: [
            {
              min_area: 65.0,
              off: 0.1,
            },
            {
              min_area: 35.0,
              off: 0.05,
            },
            {
              min_area: 25.0,
              off: 0.03,
            },
          ],
        },
        // Jacuzzi
        {
          min_dimention: 0.0,
          price: 11.22,
          price_designed: 11.22,
          name: 'Jacuzzi',
          offerts: [],
        },
      ],
    },
    // Cobertor Opaco
    {
      id: '2-thermal',
      name: 'Opaco',
      fullName: 'Cobertor Opaco',
      type: 'cover',
      prices: [
        // Piscina Olímpica
        {
          min_dimention: 1000.0,
          price: 6.95,
          price_designed: 6.95,
          name: 'Piscina Olímpica',
          offerts: [],
        },
        // Piscina Semiolímpica
        {
          min_dimention: 300.0,
          price: 8.93,
          price_designed: 8.93,
          name: 'Piscina Semiolímpica',
          offerts: [],
        },
        // Piscina Comercial
        {
          min_dimention: 100.0,
          price: 9.21,
          price_designed: 9.21,
          name: 'Piscina Comercial',
          offerts: [
            {
              min_area: 250.01,
              off: 0.05,
            },
          ],
        },
        // Piscina Doméstica
        {
          min_dimention: 10.0,
          price: 9.37,
          price_designed: 11.37,
          name: 'Piscina Doméstica',
          offerts: [
            {
              min_area: 65.0,
              off: 0.1,
            },
            {
              min_area: 35.0,
              off: 0.05,
            },
            {
              min_area: 25.0,
              off: 0.03,
            },
          ],
        },
        // Jacuzzi
        {
          min_dimention: 0.0,
          price: 10.76,
          price_designed: 10.76,
          name: 'Jacuzzi',
          offerts: [],
        },
      ],
    },
  ],
}

const getters = {
  productsThermal: (state) => state.productsThermal,
}

const mutations = {}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
}
