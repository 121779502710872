const year2018 = [
  //Inconcluso
  {
    stringX: 'Diciembre 2018',
    date: new Date('12-01-2018'),
    earnings: {
      valueY: 217.98 + 43.71 + 19.38 + 58.17,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 4,
    },
  },
  {
    stringX: 'Noviembre 2018',
    date: new Date('11-01-2018'),
    earnings: {
      valueY: 238.74 + 84.31 + 92.06 + 47.62,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 4,
    },
  },
  {
    stringX: 'Octubre 2018',
    date: new Date('10-01-2018'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Septiembre 2018',
    date: new Date('09-01-2018'),
    earnings: {
      valueY: 3598.57,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Agosto 2018',
    date: new Date('08-01-2018'),
    earnings: {
      valueY: 162.09,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Julio 2018',
    date: new Date('07-01-2018'),
    earnings: {
      valueY: 318.93,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Junio 2018',
    date: new Date('06-01-2018'),
    earnings: {
      valueY: 144.99 + 40.2 + 339.6,
    },
    expenses: {
      valueY: 50.27,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Mayo 2018',
    date: new Date('05-01-2018'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 44.84 + 667.25 / 3.31 + 243.0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Abril 2018',
    date: new Date('04-01-2018'),
    earnings: {
      valueY: 339.6,
    },
    expenses: {
      valueY: 286.74,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Marzo 2018',
    date: new Date('03-01-2018'),
    earnings: {
      valueY: 175.2,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Febrero 2018',
    date: new Date('02-01-2018'),
    earnings: {
      valueY: 87 + 98.02,
    },
    expenses: {
      valueY: 286.74,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Enero 2018',
    date: new Date('01-01-2018'),
    earnings: {
      valueY: 115.95 + 130.15 + 141.95 + 312.5,
    },
    expenses: {
      valueY: 303.51,
    },
    jobs: {
      valueY: 4,
    },
  },
]

export default year2018.reverse()
