<template>
  <nav>
    <div class="navbar">
      <div class="container nav-container">
        <input class="checkbox" type="checkbox" />
        <div class="hamburger-lines">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
        <div class="logo"></div>
        <div class="menu-items">
          <li v-for="item in menu" :key="item.key">
            <span v-if="item.type === 'url'">
              <a
                :href="`${item.to}`"
                :target="item.target"
                rel="noopenner noreferrer"
              >
                {{ item.title }}
              </a>
            </span>
          </li>
          <div v-if="exchangeCurrency" class="exchange__box">
            <p class="subtitle__box">Tipo de cambio Pool Work Confort</p>
            <p class="extra__text">
              Actualizado el
              {{
                dayJs(exchangeCurrency.last_update).format(
                  'DD/MM/YYYY hh:mm:ss a'
                )
              }}
            </p>
            <div class="exchange__box_content">
              <div class="col__left"><p>Dólar</p></div>
              <div class="col__rigth">
                <p>$.{{ exchangeCurrency.US }}</p>
              </div>
              <div class="col__left"><p>Soles</p></div>
              <div class="col__rigth">
                <p>S/.{{ exchangeCurrency.PEN }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
dayjs.locale('es')

export default {
  name: 'MenuSettings',
  data: () => ({
    menu: [
      {
        key: 'webpage',
        title: 'Ir a la Web',
        type: 'url',
        to: 'https://www.poolworkconf.com/',
        target: '_blank',
      },
      {
        key: 'review',
        title: 'Opiniones',
        type: 'url',
        to: 'https://es.trustpilot.com/review/poolworkconf.com',
        target: '_blank',
      },
      {
        key: 'charts',
        title: 'Gráficos',
        type: 'url',
        to: '/historial',
        target: '',
      },
      // {
      //     key: "prices",
      //     title: 'Ver precios',
      //     type: 'url',
      //     to: '/prices',
      // },
    ],
    dayJs: dayjs,
  }),
  created() {
    this.getLastExchangeCurrency()
  },
  computed: {
    ...mapGetters(['exchangeCurrency']),
  },
  methods: {
    ...mapActions(['getLastExchangeCurrency']),
    hide() {
      this.$emit('hideMenu', false)
      console.log('hideMenu')
    },
    noPropagate(e) {
      e.stopPropagation()
    },
  },
}
</script>

<style lang="sass" scoped>
nav
  .container
    max-width: 1050px
    width: 90%
    margin: auto

  .navbar
    width: 100%
    .menu-items
      display: flex
    .nav-container li
      list-style: none
    .nav-container a
      text-decoration: none
      color: #0e2431
      font-weight: 500
      font-size: 1.2rem
      padding: 0.7rem
    .nav-container a:hover
        font-weight: bolder
    .menu-items
      width: 100vw
      height: 100vh
      background: #fff
      position: fixed
      top: 0
      left: 0
      z-index: 50
      padding: 120px 0 0 0
      box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5)
      height: 100vh
      width: 100%
      transform: translate(-150%)
      display: flex
      flex-direction: column
      margin-left: -40px
      padding-left: 40px
      transition: transform 0.5s ease-in-out
      text-align: center
    .menu-items li
      margin-bottom: 1.2rem
      font-size: 1.5rem
      font-weight: 500

  .nav-container
    display: block
    position: relative
    height: 60px
    .checkbox
      position: absolute
      display: block
      height: 32px
      width: 32px
      top: 20px
      left: 20px
      z-index: 5
      opacity: 0
      cursor: pointer
      z-index: 52
    .hamburger-lines
      display: block
      height: 16px
      width: 20px
      position: absolute
      top: 17px
      left: 20px
      z-index: 2
      display: flex
      flex-direction: column
      justify-content: space-between
      z-index: 51
    .hamburger-lines .line
      display: block
      height: 4px
      width: 100%
      border-radius: 10px
      background: #6D6D6D
    .hamburger-lines .line1
      transform-origin: 0% 0%
      transition: transform 0.4s ease-in-out
    .hamburger-lines .line2
      transition: transform 0.2s ease-in-out
    .hamburger-lines .line3
      transform-origin: 0% 100%
      transition: transform 0.4s ease-in-out
    input[type="checkbox"]:checked ~ .menu-items
      transform: translateX(0)
    input[type="checkbox"]:checked ~ .hamburger-lines .line1
      transform: rotate(45deg)
    input[type="checkbox"]:checked ~ .hamburger-lines .line2
      transform: scaleY(0)
    input[type="checkbox"]:checked ~ .hamburger-lines .line3
      transform: rotate(-45deg)
    input[type="checkbox"]:checked ~ .logo
      display: none

  .logo
    position: absolute
    top: 5px
    right: 15px
    font-size: 1.2rem
    color: #0e2431

  .exchange__box
    width: 80%
    margin: 0 auto
    max-width: 500px
    margin-top: 220px
    &_content
      display: grid
      grid-template-columns: 1fr 1fr
      row-gap: 8px
      div
        padding: 8px 16px
        border: 2px solid #3c5797
        p
          margin: 0
      .col__rigth
        text-align: left
        border-radius: 0 8px 8px 0
        p
          display: inline-block
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          max-width: 9ch
          font-weight: bold
          color: #3c5797
      .col__left
        text-align: center
        border-radius: 8px 0 0 8px
        background-color: #3c5797
        font-weight: bold
        p
          color: #fff
    .subtitle__box
      font-size: 12px
      color: #3c5797
    .extra__text
      margin: 12px 0
      color: #3c5797
      font-weight: bold
      font-size: 16px
</style>
