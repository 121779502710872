const state = {
  includeIGV: true, // Incluye IGV
  igv: 0.18, // Valor del IGV
}

const getters = {
  includeIGV: (state) => state.includeIGV,
  igv: (state) => state.igv,
}

const mutations = {
  SET_INCLUDE_IGV(state, includeIGV) {
    state.includeIGV = includeIGV
  },
}

export default {
  state,
  getters,
  mutations,
}
