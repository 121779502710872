<template>
  <div class="nav-item">
    <div
      class="nav-item-content"
      :class="{ selected: nav.id === navMaterial.id, disabled: !nav.enabled }"
      @click="setNavMaterial(nav)"
    >
      <span>{{ nav.title }}</span>
      <div class="soon" v-if="!nav.enabled">
        <span>{{ soon_message }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NavItem',
  props: {
    nav: { type: Object, required: true },
  },
  data: () => ({
    soon_message: 'Proximamente',
  }),
  computed: {
    ...mapGetters(['navMaterial']),
  },
  methods: {
    ...mapActions(['setNavMaterial']),
  },
}
</script>

<style lang="sass" scoped>
@import '@/sass/global.sass'
$soon_color: #c23616

.nav-item
    &-content
        padding: 6px
        width: 80px
        text-align: center
        cursor: pointer
        span
            color: $text-color-dark
            font-size: 14px
        &:hover
            background: #c3c3c317
    .selected
        border-top: 2px solid $primary-color
    .disabled
        cursor: default
    .soon
        // position: absolute
        width: 52px
        border: 1px solid $soon_color
        padding: 1px 5px
        border-radius: 5px
        transform: rotate(-15deg)
        margin-top: -13px
        margin-left: 33px
        background: #ffffff85
        z-index: 1
        span
            font-size: 8px
            color: $soon_color
</style>
