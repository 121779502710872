import Vue from 'vue'
import Vuex from 'vuex'
import taxesModule from './modules/taxes.js'
import tabsModule from './modules/tabs.js'
import calcModule from './modules/calc.js'
import themalModule from './modules/thermal.js'
import canvasModule from './modules/canvas.js'
import dashboardData from './modules/dashboardData.js'
import exchangesModule from './modules/exchanges.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    taxesModule,
    tabsModule,
    calcModule,
    themalModule,
    canvasModule,
    dashboardData,
    exchangesModule,
  },
})
