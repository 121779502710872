const year2016 = [
  {
    stringX: 'Diciembre 2016',
    date: new Date('12-01-2016'),
    earnings: {
      valueY: 130.5 + (134.22 + 126.15) + (308.7 + 203.11 + 91.86),
    },
    expenses: {
      valueY: 59.81 + 286.74,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Noviembre 2016',
    date: new Date('11-01-2016'),
    earnings: {
      valueY: 180.61 + 370,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Octubre 2016',
    date: new Date('10-01-2016'),
    earnings: {
      valueY: 58.83 + 98.95 + 259.57,
    },
    expenses: {
      valueY: 286.74,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Septiembre 2016',
    date: new Date('09-01-2016'),
    earnings: {
      valueY: 261 + 115.46,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Agosto 2016',
    date: new Date('08-01-2016'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Julio 2016',
    date: new Date('07-01-2016'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Junio 2016',
    date: new Date('06-01-2016'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Mayo 2016',
    date: new Date('05-01-2016'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Abril 2016',
    date: new Date('04-01-2016'),
    earnings: {
      valueY: 98.6 + 89.25 + 118.75 + 72.6 + 210.62 + 565.65,
    },
    expenses: {
      valueY: 51.97 + 286.74,
    },
    jobs: {
      valueY: 6,
    },
  },
  {
    stringX: 'Marzo 2016',
    date: new Date('03-01-2016'),
    earnings: {
      valueY: 44.34 + 165.83 + 150 + 300,
    },
    expenses: {
      valueY: 286.74,
    },
    jobs: {
      valueY: 4,
    },
  },
  {
    stringX: 'Febrero 2016',
    date: new Date('02-01-2016'),
    earnings: {
      valueY: 295.56 + 109.62 + 210,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Enero 2016',
    date: new Date('01-01-2016'),
    earnings: {
      valueY: 46.98 + 117.2 + 399.72 + 143.4 + 120,
    },
    expenses: {
      valueY: 286.74 + 172.04,
    },
    jobs: {
      valueY: 5,
    },
  },
]

export default year2016.reverse()
