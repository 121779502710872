<template>
  <div class="settings">
    <MenuSettings v-show="menuVisible" @hideMenu="showMenu" />
  </div>
</template>

<script>
import MenuSettings from '@/components/header/MenuSettings.vue'

export default {
  name: 'Settings',
  components: {
    MenuSettings,
  },
  data: () => ({
    menuVisible: true,
  }),
  methods: {
    showMenu(visible) {
      this.menuVisible = visible
    },
  },
}
</script>

<style lang="sass" scoped>
.settings
  i
    svg
      width: 20px
      heigth: 20px
</style>
