const year2017 = [
  {
    stringX: 'Diciembre 2017',
    date: new Date('12-01-2017'),
    earnings: {
      valueY: 70.88 + 175.45 + (107.92 + 107.92),
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Noviembre 2017',
    date: new Date('11-01-2017'),
    earnings: {
      valueY: 109.62,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Octubre 2017',
    date: new Date('10-01-2017'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Septiembre 2017',
    date: new Date('09-01-2017'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Agosto 2017',
    date: new Date('08-01-2017'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Julio 2017',
    date: new Date('07-01-2017'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Junio 2017',
    date: new Date('06-01-2017'),
    earnings: {
      valueY: 792.06 + 784.31,
    },
    expenses: {
      valueY: 286.0,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Mayo 2017',
    date: new Date('05-01-2017'),
    earnings: {
      valueY: 174 + 183.57,
    },
    expenses: {
      valueY: 34.56, //Verificar precio
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Abril 2017',
    date: new Date('04-01-2017'),
    earnings: {
      valueY: 217.5,
    },
    expenses: {
      valueY: 303.51,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Marzo 2017',
    date: new Date('03-01-2017'),
    earnings: {
      valueY: 113.79,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Febrero 2017',
    date: new Date('02-01-2017'),
    earnings: {
      valueY: 83.52,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Enero 2017',
    date: new Date('01-01-2017'),
    earnings: {
      valueY: 51.25 + 132.46,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 2,
    },
  },
]

export default year2017.reverse()
