<template>
  <div class="nav">
    <div class="nav-content">
      <Head />
      <div class="band">
        <NavItem v-for="nav in navs" :key="'nav' + nav.id" :nav="nav" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Head from '@/components/header/Head.vue'
import NavItem from '@/components/header/NavItem.vue'

export default {
  name: 'Nav',
  components: {
    Head,
    NavItem,
  },
  created: function () {
    this.setFirstNav()
  },
  computed: {
    ...mapGetters(['navs']),
  },
  methods: {
    ...mapActions(['setFirstNav']),
  },
}
</script>

<style lang="sass" scoped>
@import '@/sass/global.sass'

.nav
    &-content
        .band
            margin: 8px 0px
            background: #eee
            width: 100%
            font-size: 12px
            display: flex
            border-top: 1px solid $primary-color
            overflow-x: auto
            overflow-y: hidden
</style>
