const state = {
  productsCanvas: [
    // Lona 600μm
    {
      id: '2-canvas',
      name: '600μm',
      fullName: 'Lona 600μm',
      type: 'cover',
      prices: [
        // Piscina Doméstica
        {
          min_dimention: 10.0,
          price: 23.34,
          price_designed: 30.03,
          name: 'Piscina Doméstica',
          offerts: [],
        },
        // Jacuzzi
        {
          min_dimention: 0.0,
          price: 25.46,
          price_designed: 25.46,
          name: 'Jacuzzi',
          offerts: [],
        },
      ],
    },
    // Lona 400μm
    {
      id: '1-canvas',
      name: '400μm',
      fullName: 'Lona 400μm',
      type: 'cover',
      prices: [
        // Piscina Doméstica
        {
          min_dimention: 10.0,
          price: 16.52,
          price_designed: 21.77,
          name: 'Piscina Doméstica',
          offerts: [],
        },
        // Jacuzzi
        {
          min_dimention: 0.0,
          price: 20.33,
          price_designed: 20.33,
          name: 'Jacuzzi',
          offerts: [],
        },
      ],
    },
  ],
}

const getters = {
  productsCanvas: (state) => state.productsCanvas,
}

const mutations = {}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
}
