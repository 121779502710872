const year2021 = [
  {
    stringX: 'Diciembre 2021',
    date: new Date('12-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Noviembre 2021',
    date: new Date('11-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'octubre 2021',
    date: new Date('10-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Setiembre 2021',
    date: new Date('09-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Agosto 2021',
    date: new Date('08-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Julio 2021',
    date: new Date('07-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Junio 2021',
    date: new Date('06-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Mayo 2021',
    date: new Date('05-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Abril 2021',
    date: new Date('04-01-2021'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Marzo 2021',
    date: new Date('03-01-2021'),
    earnings: {
      valueY:
        113.86 +
        80.94 +
        100 +
        756.31 +
        (1090 + 48) / 3.65 +
        66.91 +
        126.18 +
        182.44 +
        67.69,
    },
    expenses: {
      valueY: (159.96 + 325 + 54 + 131.79 + 6 + 65 + 80 + 65) / 3.65,
    },
    jobs: {
      valueY: 10,
    },
  },
  {
    stringX: 'Febrero 2021',
    date: new Date('02-01-2021'),
    earnings: {
      valueY:
        84.58 +
        50 +
        226.72 +
        98.61 +
        52 +
        68.12 +
        80.94 +
        63.81 +
        162.7 +
        165.06 +
        27.38 +
        159.35 +
        320.3 +
        92.41 +
        202.51 +
        90.65 +
        78.38 +
        (40 + 48) / 3.65,
    },
    expenses: {
      valueY: 672 + 376 + (40 + 319) / 3.65,
    },
    jobs: {
      valueY: 19,
    },
  },
  {
    stringX: 'Enero 2021',
    date: new Date('01-01-2021'),
    earnings: {
      valueY:
        71.09 +
        65.43 +
        48 / 3.62 +
        122.18 +
        48.29 +
        107.52 +
        113.45 +
        46.11 +
        121.41 +
        195.68 +
        287.76 +
        113.05 +
        90 +
        42.36 +
        77.43,
    },
    expenses: {
      valueY:
        336 +
        672 +
        (20 +
          20 +
          20 +
          15 +
          10 +
          50 +
          20 +
          14 +
          20 +
          12 +
          15 +
          15 +
          16 +
          22 +
          136 +
          13 +
          29 +
          17 +
          33 +
          20 +
          12 +
          12 +
          70 +
          10 +
          12 +
          70 +
          5.5 +
          15) /
          3.62,
    },
    jobs: {
      valueY: 15,
    },
  },
]

export default year2021.reverse()
