const year2020 = [
  {
    stringX: 'Diciembre 2020',
    date: new Date('12-01-2020'),
    earnings: {
      // Hoy 24/12/2020, aun faltan entregas
      valueY:
        251.59 +
        108 / 3.6 +
        288 / 3.6 +
        210 +
        151.99 +
        97.98 +
        130.09 +
        111.7 +
        144 / 3.6 +
        101.98 +
        125.66 +
        47.15 +
        91.87 +
        105.49 +
        89.03 +
        51.26 +
        48 / 3.6,
    },
    expenses: {
      valueY:
        330 +
        (25 +
          50 +
          40 +
          300 +
          180 +
          20 +
          50 +
          25 +
          50 +
          12 +
          120 +
          20 +
          20 +
          20 +
          15 +
          15 +
          10 +
          20 +
          15 +
          20 +
          20) /
          3.6,
    },
    jobs: {
      valueY: 17,
    },
  },
  {
    stringX: 'Noviembre 2020',
    date: new Date('11-01-2020'),
    earnings: {
      valueY: 678.4 + 98.61 + 215.22 + 66.78 + 76.62 + 287.98 + 757.59,
    },
    expenses: {
      valueY:
        (359 +
          20 +
          20 +
          90 +
          40 +
          50 +
          100 +
          20 +
          520.55 +
          20 +
          50 +
          40 +
          50 +
          40 +
          25) /
        3.59, //Todo dentro del parentesis esta en soles
    },
    jobs: {
      valueY: 7,
    },
  },
  {
    stringX: 'octubre 2020',
    date: new Date('10-01-2020'),
    earnings: {
      valueY:
        234.14 +
        98.21 +
        192 / 3.6 +
        100 / 3.6 +
        115.1 +
        121.14 +
        50 / 3.6 +
        400.0 +
        96.32 +
        287.76 +
        54.56,
    },
    expenses: {
      valueY:
        (461.26 * 3.6 +
          50 +
          928.74 * 3.6 +
          20.74 * 3.6 +
          80.0 * 3.6 +
          95 +
          20 +
          20 +
          20 +
          20) /
        3.6, //Todo dentro del parentesis esta en soles
    },
    jobs: {
      valueY: 11,
    },
  },
  {
    stringX: 'Setiembre 2020',
    date: new Date('09-01-2020'),
    earnings: {
      valueY: 54.56 + 31.79,
    },
    expenses: {
      valueY: 110.57,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Agosto 2020',
    date: new Date('08-01-2020'),
    earnings: {
      valueY: 404.64,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Julio 2020',
    date: new Date('07-01-2020'),
    earnings: {
      valueY: 1202.28,
    },
    expenses: {
      valueY: 343.99 + 776.69 / 3.55 + 148.42,
    },
    jobs: {
      valueY: 7,
    },
  },
  {
    stringX: 'Junio 2020',
    date: new Date('06-01-2020'),
    earnings: {
      valueY: 322.82,
    },
    expenses: {
      valueY: 660,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Mayo 2020',
    date: new Date('05-01-2020'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 8,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Abril 2020',
    date: new Date('04-01-2020'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 7,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Marzo 2020',
    date: new Date('03-01-2020'),
    earnings: {
      valueY: 62.59,
    },
    expenses: {
      valueY: 28,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Febrero 2020',
    date: new Date('02-01-2020'),
    earnings: {
      valueY: 113.57,
    },
    expenses: {
      valueY: 9,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Enero 2020',
    date: new Date('01-01-2020'),
    earnings: {
      valueY: 711.45,
    },
    expenses: {
      valueY: 8,
    },
    jobs: {
      valueY: 8,
    },
  },
]

export default year2020.reverse()
