import year2015 from './history/2015'
import year2016 from './history/2016'
import year2017 from './history/2017'
import year2018 from './history/2018'
import year2019 from './history/2019'
import year2020 from './history/2020'
import year2021 from './history/2021'
// import year2022 from './history/2022'

const state = {
  filterDashboard: {
    year: 2021, // Año por defecto del filtro
    month: 0, // ïndice de la posiciónn del mes en el filtro
  },
  fullHistory: year2015
    .concat(year2016)
    .concat(year2017)
    .concat(year2018)
    .concat(year2019)
    .concat(year2020)
    .concat(year2021)
    .reverse(),
}

const getters = {
  dataHistorialFiltered: (store) =>
    store.fullHistory.filter((el) => {
      if (store.filterDashboard.year == 0) return true
      else return el.date.getFullYear() == store.filterDashboard.year
    }),
}

const mutations = {
  /**
   *
   * @param {*} state
   * @param {Number} year
   */
  SET_FILTER_YEAR(state, year) {
    state.filterDashboard.year = year
  },
  /**
   *
   * @param {*} state
   * @param {Number} month
   */
  SET_FILTER_MONTH(state, month) {
    state.filterDashboard.month = month
  },
}

export default {
  state,
  getters,
  mutations,
}
