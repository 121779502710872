const year2015 = [
  {
    stringX: 'Diciembre 2015',
    date: new Date('12-01-2015'),
    earnings: {
      valueY: 198.4,
    },
    expenses: {
      valueY: 172.04,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Noviembre 2015',
    date: new Date('11-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Octubre 2015',
    date: new Date('10-01-2015'),
    earnings: {
      valueY: 125 + 150,
    },
    expenses: {
      valueY: 172.04 + 172.04,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Septiembre 2015',
    date: new Date('09-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Agosto 2015',
    date: new Date('08-01-2015'),
    earnings: {
      valueY: 169.7 + 115.15,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Julio 2015',
    date: new Date('07-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Junio 2015',
    date: new Date('06-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Mayo 2015',
    date: new Date('05-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Abril 2015',
    date: new Date('04-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Marzo 2015',
    date: new Date('03-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Febrero 2015',
    date: new Date('02-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Enero 2015',
    date: new Date('01-01-2015'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
]

export default year2015.reverse()
