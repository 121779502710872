const year2019 = [
  {
    stringX: 'Diciembre 2019',
    date: new Date('12-01-2019'),
    earnings: {
      valueY: 332.34,
    },
    expenses: {
      valueY: 6,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Noviembre 2019',
    date: new Date('12-01-2019'),
    earnings: {
      valueY: 254.55,
    },
    expenses: {
      valueY: 8,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Octubre 2019',
    date: new Date('10-01-2019'),
    earnings: {
      valueY: 663.63,
    },
    expenses: {
      valueY: 660,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Septiembre 2019',
    date: new Date('10-01-2019'),
    earnings: {
      valueY: 179.85,
    },
    expenses: {
      valueY: 8,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Agosto 2019',
    date: new Date('08-01-2019'),
    earnings: {
      valueY: 26.3,
    },
    expenses: {
      valueY: 40,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Julio 2019',
    date: new Date('07-01-2019'),
    earnings: {
      valueY: 512.42,
    },
    expenses: {
      valueY: 330 + 20.74,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Junio 2019',
    date: new Date('06-01-2019'),
    earnings: {
      valueY: 0,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 0,
    },
  },
  {
    stringX: 'Mayo 2019',
    date: new Date('05-01-2019'),
    earnings: {
      valueY: 223.82,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Abril 2019',
    date: new Date('04-01-2019'),
    earnings: {
      valueY: 136.26,
    },
    expenses: {
      valueY: 22.5 / 3.3 + 60.3,
    },
    jobs: {
      valueY: 1,
    },
  },
  {
    stringX: 'Marzo 2019',
    date: new Date('03-01-2019'),
    earnings: {
      valueY: 378.89,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 2,
    },
  },
  {
    stringX: 'Febrero 2019',
    date: new Date('02-01-2019'),
    earnings: {
      valueY: 542.26,
    },
    expenses: {
      valueY: 0,
    },
    jobs: {
      valueY: 3,
    },
  },
  {
    stringX: 'Enero 2019',
    date: new Date('01-01-2019'),
    earnings: {
      valueY: 34.35 + 143.88 + 174.4 + 72.74 + 67.45 + 65.41 + 104.95,
    },
    expenses: {
      valueY: 47.73 + 40.34,
    },
    jobs: {
      valueY: 7,
    },
  },
]

export default year2019.reverse()
