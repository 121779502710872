<template>
  <div id="app">
    <Nav v-if="!routesExceptions.includes($route.name)" />
    <router-view />
  </div>
</template>

<script>
import Nav from '@/components/header/Nav.vue'

export default {
  name: 'App',
  components: {
    Nav,
  },
  data: () => ({
    routesExceptions: [
      // Rutas en las que no aparecerá el navegador
      'historial',
    ],
  }),
}
</script>

<style lang="sass">

#app
  font-family: 'Roboto', Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50
</style>
