const state = {
  navMaterial: {},
  navs: [
    {
      id: 1,
      title: 'Aquasol',
      enabled: true,
    },
    {
      id: 2,
      title: 'Lona',
      enabled: true,
    },
    {
      id: 3,
      title: 'Malla',
      enabled: false,
    },
    {
      id: 4,
      title: 'Cerco',
      enabled: false,
    },
    // {
    //   id: 5,
    //   title: 'Toallas',
    //   enabled: false,
    // },
  ],
}

const getters = {
  navMaterial: (state) => state.navMaterial,
  navs: (state) => state.navs,
}

const mutations = {
  SET_FIRST_NAV(state) {
    state.navMaterial = state.navs[0]
  },
  SET_NAV_MATERIAL(state, navMaterial) {
    state.navMaterial = navMaterial
  },
}

const actions = {
  async setFirstNav(context) {
    context.commit('SET_FIRST_NAV')
  },
  async setNavMaterial(context, navMaterial) {
    if (!navMaterial.enabled) return

    context.commit('SET_NAV_MATERIAL', navMaterial)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
